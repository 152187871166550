/*!
 *
 *  smooth-scroll.ts
 *
 */
export const smoothScrollCore = (target, duration) => {
    const targetPosition = target.getBoundingClientRect().top + window.scrollY - util.scrollGap();
    const startPosition = window.scrollY;
    const distance = Math.min(targetPosition - startPosition, document.documentElement.scrollHeight - window.innerHeight - startPosition);
    if (duration <= 0) {
        window.scrollTo(0, targetPosition);
        return;
    }
    ;
    let startTime = null;
    const animation = (currentTime) => {
        if (startTime === null) {
            startTime = currentTime;
        }
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) {
            requestAnimationFrame(animation);
        }
        else {
            window.scrollTo(0, targetPosition);
        }
    };
    function ease(t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    }
    ;
    requestAnimationFrame(animation);
};
const smoothScroll = (options = {}) => {
    const ignore = options.ignore || '[data-scroll-ignore]';
    const duration = options.duration || 1000;
    const links = document.querySelectorAll(`a[href^="#"]:not(${ignore}), a[href^="${location.pathname}#"]:not(${ignore}), a[href^="${location.origin}${location.pathname}#"]:not(${ignore})`);
    links.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            let href = link.getAttribute('href').replace(location.origin, '').replace(location.pathname, '').replace(/^#/, '');
            const target = document.getElementById(href);
            if (target) {
                smoothScrollCore(target, duration);
            }
            ;
        });
    });
};
export default smoothScroll;
