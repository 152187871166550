/*!
 *
 * utility.ts
 *
 */
const BREAKPOINTS = {
    phone: 768,
    tablet: 993,
    desktop: 1280,
    largeDesktop: Infinity,
};
const gap = {
    selector: 'header',
    spselector: 'header',
    viewport: {
        phone: true,
        tablet: true,
        desktop: true,
        largeDesktop: true,
    },
};
const getViewport = (ww) => {
    if (ww < BREAKPOINTS.phone) {
        return 'phone';
    }
    else if (ww < BREAKPOINTS.tablet) {
        return 'tablet';
    }
    else if (ww < BREAKPOINTS.desktop) {
        return 'desktop';
    }
    else {
        return 'largeDesktop';
    }
};
const getScrollbarWidth = () => {
    const element = document.createElement('div');
    element.style.visibility = 'hidden';
    element.style.overflow = 'scroll';
    document.body.appendChild(element);
    const scrollbarWidth = element.offsetWidth - element.clientWidth;
    document.body.removeChild(element);
    return scrollbarWidth;
};
const html = document.documentElement;
const setSize = () => {
    const vh = window.innerHeight * 0.01;
    html.style.setProperty('--vh', `${vh}px`);
    const scrollbarWidth = getScrollbarWidth();
    if (scrollbarWidth > 0) {
        html.style.setProperty('--scroll-bar', `${scrollbarWidth}px`);
    }
};
// const telLinkManager = (): void => {
// 	const telLinks = document.querySelectorAll<HTMLElement>(`a[href^="tel:"]`);
// 	const isPhone = /iPhone|Android/.test(navigator.userAgent)
// 	if (isPhone) {
// 		telLinks.forEach((link: HTMLElement) => {
// 			link.classList.add('u-tel', '-enabled');
// 		});
// 	} else {
// 		telLinks.forEach((link: HTMLElement) => {
// 			link.classList.add('u-tel', '-disabled');
// 			link.addEventListener('click', (e) => {
// 				e.preventDefault();
// 			});
// 		});
// 	};
// };
const handleResize = () => {
    const newViewport = getViewport(window.innerWidth);
    if (util.viewport !== newViewport) {
        util.viewport = newViewport;
        window.dispatchEvent(viewportChanged);
    }
    setSize();
};
const handleLoad = () => {
    // telLinkManager();
    setSize();
};
const initUtil = () => {
    const initialViewport = getViewport(window.innerWidth);
    const util = {
        ww: window.innerWidth,
        wh: window.innerHeight,
        hash: location.hash,
        viewport: initialViewport,
        scroller: 'html,body',
        scrollGap: () => {
            const gapSelector = initialViewport === 'phone' ? gap.spselector : gap.selector;
            const gapElement = document.getElementById(gapSelector);
            const hasGapElement = gapElement !== null;
            const gapHeight = hasGapElement ? gapElement.offsetHeight : 112;
            const gapMargin = 20;
            return gapHeight + gapMargin;
        },
        isTouchDevice: 'ontouchstart' in window,
    };
    if (util.isTouchDevice) {
        const body = document.body;
        body.setAttribute('ontouchstart', '');
    }
    return util;
};
const util = initUtil();
const viewportChanged = new CustomEvent('viewportChanged');
window.addEventListener('resize', handleResize, { passive: true });
document.readyState !== "loading" ? handleLoad() : document.addEventListener("DOMContentLoaded", handleLoad);
window.addEventListener('load', handleLoad, { passive: true });
export default util;
