/*!
 *
 *  header.ts
 *
 */
export default function () {
    const header = document.querySelector('#header');
    let h = header.clientHeight;
    window.addEventListener('viewportChanged', () => {
        h = header.clientHeight;
    });
    window.addEventListener('scroll', () => {
        if (window.scrollY > h) {
            header.classList.add('-shrink');
        }
        else {
            header.classList.remove('-shrink');
        }
        ;
    });
}
