/*!
 *
 *  scroll2hash.js
 *
 */
const scroll2hash = () => {
    const hash = util.hash;
    if (hash.length > 0 && !hash.includes('/')) {
        location.hash = `${hash}_`;
        window.addEventListener('load', () => {
            const target = document.querySelector(hash);
            if (target) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const top = target.getBoundingClientRect().top + scrollTop - util.scrollGap();
                setTimeout(() => {
                    window.scrollTo({ top, behavior: 'auto' });
                }, 10);
                history.replaceState(null, '', util.hash);
            }
            else {
                history.replaceState(null, '', util.hash);
            }
        });
    }
};
export default scroll2hash;
