export default function (elemName) {
    const elems = document.querySelectorAll(elemName);
    const path = window.location.pathname;
    elems.forEach((elem) => {
        let href = elem.getAttribute('href');
        if (path === href) {
            elem.setAttribute('aria-current', 'true');
        }
        ;
    });
}
