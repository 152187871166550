/*!
 *
 * app.js
 *
 */
/*** ./modules **/
import scroll2hash from 'scroll2hash';
import SmoothScroll from 'smooth-scroll';
import header from 'header';
import menu from 'menu';
import linkCurrent from 'link-current';
scroll2hash();
function init() {
    SmoothScroll();
    new menu({
        btn: '#menuButton',
        container: '#menuContainer',
    });
    new menu({
        btn: '#searchButton',
        container: '#searchContainer',
        shouldHide: ['phone', 'tablet', 'desktop', 'largeDesktop'],
    });
    linkCurrent('.site-global__link');
    header();
    initHeroSlider();
    initSetScrollBar();
    initZoom();
}
;
async function initHeroSlider() {
    const elem = document.querySelector('#topHeroSlider');
    if (elem) {
        const { default: HeroSlider } = await import(
        /* webpackChunkName: "hero-slider" */ 'hero-slider');
        HeroSlider(elem);
    }
}
async function initSetScrollBar() {
    const elem = document.querySelector('.page-bar');
    if (elem) {
        const { default: SetScrollbar } = await import(
        /* webpackChunkName: "set-scrollbar" */ 'set-scrollbar');
        SetScrollbar();
    }
}
async function initZoom() {
    const elem = document.querySelector('.c-zoom');
    if (elem) {
        const { default: Zoom } = await import(
        /* webpackChunkName: "zoom" */ 'zoom');
        Zoom('.c-zoom');
    }
    ;
}
document.readyState !== "loading" ? init() : document.addEventListener("DOMContentLoaded", init);
